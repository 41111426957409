.navbar {
    position: absolute;
    top: 0;
    height: 50px;
    width: 94%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 32px 32px 32px 32px;
    /* background-color: #333; */
    color: white;
    z-index: 1000; /* Ensure navbar stays on top of other content */
  }
  .logo-container{
    max-height: 32px;
    padding: 8px 8px 32px 32px;
  }
  .logo-container img{
    height: 32px;
    color: grey
  }

  .right {
    align-items: center;
    justify-content: center;
  }
  .navbar ul {
    list-style: none;
    display: flex;
    gap: 1rem;
  }

  .nav-links {
    font-weight: 600;
    margin-right: 32px;
    align-self: center;
  }
  
  .navbar a {
    color: black;
    text-decoration: none;
  }
  
  .logo {
    font-weight: 600;
    font-size: 35px;
  }