  .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    /* background-color: #0056b3; */
  }
  
  .left-panel, .right-panel {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    /* background-color: rebeccapurple; */
  }
  
  .left-panel p {
    font-size: 24px;
    font-weight: bold;
  }

  .left-panel img.desktop-image {
    display: block; /* Show desktop image by default */
    max-height: 900px;
  }
  
  .left-panel img.mobile-image {
    display: none; /* Hide mobile image by default */
  }
  .fical-logo { 
    /* font-weight: 500; */
  }

  .fical-branding {
    text-align: flex-start;
    align-items: start;
    /* border-width: 1px; */
    /* border-color: #f1f1f1; */
    /* border-style: solid; */
    padding: 40px 40px 40px 80px;
    border-radius: 30px;
  }
  
  .fical-branding h1 {
    font-size: 52px;
    margin-bottom: 20px;
    font-weight: 900;
    text-align: center;
  }

  
  .fical-branding h3 {
    font-size: 20px;
    margin-bottom: 20px;
    font-weight: 400;
    text-align: start;
    line-height: 32px;
  }
  
  .fical-branding button {
    padding: 16px 88px;
    width: 100%;
    font-size: 18px;
    font-weight: 900;
    background-color: #1a8cff;
    border: none;
    border-radius: 40px;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .fical-branding button:hover {
    background-color: #0056b3;
  }
  
  .footer {
    position: absolute;
    align-self: center;
    bottom: 20px;
    justify-content: space-between;
    width: 100%;
    font-size: 14px;
  }

  .content-wrapper {
    display: flex;
    flex-direction: row;
  }
  .footer-wrapper {
    display: flex;
    align-items: center; /* This will align the children vertically in the center if your flex-direction is column */
    justify-content: center; /* This will align the children horizontally in the center */
    /* You can add padding if you want some space inside the .footer-wrapper */
    padding: 10px;
  }
  .footer-wrapper p {
    margin-right: 8px
  }
  
  
  .footer span {
    margin: 0 10px;
  }
  /* Existing styles for desktop here */

/* ... other styles ... */

/* Mobile screen sizes */
@media (max-width: 768px) { /* Adjust the max-width as needed for your target mobile size */
  .left-panel img.desktop-image {
    display: none; /* Hide the desktop image on mobile */
  }

  .left-panel img.mobile-image {
    display: block; /* Show the mobile image on mobile */
    max-height: 500px;
  }
  .container {
    flex-direction: column; /* Stacks elements vertically on smaller screens */
  }
  
  .left-panel, .right-panel {
    width: 100%; /* Takes full width on smaller screens */
  }
  
  .right-panel h3 {
    text-align: center;
  }
  .left-panel img {
    height: auto; /* Adjusts the height automatically */
    max-width: 100%; /* Ensures image is not wider than the screen */
  }
  
  .fical-branding {
    padding: 20px; /* Reduced padding for smaller screens */
    text-align: center; /* Center text for smaller screens */
    align-items: center; /* Align items to the center */
  }
  
  .fical-branding h1 {
    font-size: 52px; /* Smaller font size for smaller screens */
  }
  
  .fical-branding h3 {
    font-size: 18px; /* Smaller font size for smaller screens */
    line-height: 24px; /* Adjust line height for readability */
  }
  
  .fical-branding button {
    padding: 12px 44px; /* Smaller padding for smaller screens */
    font-size: 16px; /* Smaller font size for smaller buttons */
    border-radius: 20px; /* Adjust border-radius for aesthetic */
  }
  
  .footer {
    position: relative; /* Change positioning for mobile if needed */
    bottom: 0; /* Adjust bottom spacing */
  }

  .content-wrapper {
    flex-direction: column; /* Stacks elements vertically on smaller screens */
    margin-top: 100px;
  }
  
  /* Other styles as needed for mobile */
}
